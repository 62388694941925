import React, { useEffect, useState } from 'react';
import { Input } from './components/ui/Input';
import { fetchDailySummaries, fetchThreadDataWithPrompt } from './api';
import ReactMarkdown from 'react-markdown';
import { Avatar, AvatarImage, AvatarFallback } from './components/ui/Avatar';
import { Button } from './components/ui/Button';

const NewsCard = () => {
  const [summaries, setSummaries] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const now = new Date();
        const utcHour = now.getUTCHours();
        const date = new Date(now);

        // If the current time is before 1 PM UTC, use the previous day
        if (utcHour < 13) {
          date.setUTCDate(now.getUTCDate() - 1);
        }

        const formattedDate = date.toISOString().split('T')[0]; // YYYY-MM-DD format
        const data = await fetchDailySummaries(formattedDate);
        setSummaries(data);
      } catch (error) {
        console.error('Failed to fetch daily summaries:', error);
      }
    };
    getData();
  }, []);

  const handlePromptSubmit = async () => {
    const hash = summaries[currentIndex].hash;
    try {
      const data = await fetchThreadDataWithPrompt(hash, prompt);
      const updatedSummaries = summaries.map((summary, index) =>
        index === currentIndex ? { ...summary, aiResult: data.ai_result } : summary
      );
      setSummaries(updatedSummaries);
      setPrompt('');
    } catch (error) {
      console.error('Failed to fetch thread data with custom prompt:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default action of Enter key
      handlePromptSubmit();
    }
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? summaries.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === summaries.length - 1 ? 0 : prevIndex + 1));
  };

  if (summaries.length === 0) {
    return <p>Loading...</p>;
  }

  const currentSummary = summaries[currentIndex];

  return (
    <div className="max-w-[800px] mx-auto p-6 border bg-gray-100 text-gray-800">
      <header className="mb-6">
        <h1 className="text-4xl font-bold font-serif">Today's Top News</h1>
        <p className="text-xl font-serif">Casted {new Date().toLocaleDateString()}</p>
      </header>
      <article className="border-t pt-4">
        <div className="flex items-center mb-4">
          <Avatar>
            <AvatarImage src={currentSummary.authorPfp} />
            <AvatarFallback>{currentSummary.authorUsername.charAt(0)}</AvatarFallback>
          </Avatar>
          <div className="ml-4">
            <p className="font-serif text-lg font-bold">@{currentSummary.authorUsername}</p>
            <p className="font-serif text-sm">{currentSummary.authorDisplayName}</p>
          </div>
          <p className="ml-auto text-sm font-serif">
            Posted on {new Date(currentSummary.castTimestamp).toLocaleDateString(undefined, { timeZone: 'UTC' })}
          </p>
        </div>
        <div className="font-serif text-lg mb-6 leading-tight">
          <ReactMarkdown>{currentSummary.initialCastText}</ReactMarkdown>
        </div>
        <section className="mb-6">
          <h2 className="font-serif text-lg font-bold mb-2">Community Buzz</h2>
          <div className="community-buzz">
            <ReactMarkdown
              components={{
                p: ({ node, ...props }) => <div className="mb-2" {...props} />,
                br: () => <br className="leading-loose" />,
              }}
            >
              {currentSummary.aiResult}
            </ReactMarkdown>
          </div>
        </section>
        <section>
          <h2 className="font-serif text-lg font-bold mb-2">Ask a Question</h2>
          <Input
            placeholder="Ask a question..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleKeyDown}
            className="font-serif text-lg"
          />
        </section>
        <div className="flex justify-between mt-6">
          <Button variant="ghost" size="icon" onClick={handlePrevClick}>
            <ChevronLeftIcon className="w-6 h-6" />
            <span className="sr-only">Previous</span>
          </Button>
          <Button variant="ghost" size="icon" onClick={handleNextClick}>
            <ChevronRightIcon className="w-6 h-6" />
            <span className="sr-only">Next</span>
          </Button>
        </div>
      </article>
    </div>
  );
};

function ChevronLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}

function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
}

export default NewsCard;
