// src/components/ui/Button.js
import React from 'react';

export const Button = ({ variant, size, onClick, children }) => {
  const baseClasses = 'inline-flex items-center justify-center rounded-full focus:outline-none';
  const variantClasses = variant === 'ghost' ? 'bg-transparent hover:bg-gray-200' : '';
  const sizeClasses = size === 'icon' ? 'p-2' : 'px-4 py-2';

  return (
    <button onClick={onClick} className={`${baseClasses} ${variantClasses} ${sizeClasses}`}>
      {children}
    </button>
  );
};
