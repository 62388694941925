// src/Aside.js
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/Card';
import { fetchChannelSummaries } from './api';
import ReactMarkdown from 'react-markdown';

const Aside = ({ onSelectChannel }) => {
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const getChannels = async () => {
      try {
        const date = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
        const data = await fetchChannelSummaries(date);
        setChannels(Object.values(data)); // Convert the object to an array of channel summaries
      } catch (error) {
        console.error('Failed to fetch channel summaries:', error);
      }
    };
    getChannels();
  }, []);

  return (
    <aside className="w-full md:w-1/3">
      <Card className="p-4 mt-4">
        <CardHeader>
          <CardTitle>Weekly Channel News</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {channels.map((channel) => (
              <div
                key={channel.channel}
                className="flex flex-col cursor-pointer hover:bg-gray-200 p-2 rounded-md"
                onClick={() => onSelectChannel(channel.channel)}
              >
                <p className="text-sm font-bold">/{channel.channel}</p>
                <ReactMarkdown className="text-sm">{channel.aiResult.split('\n')[0]}</ReactMarkdown>
                <hr />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </aside>
  );
};

export default Aside;
