import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Main from './Main';

const App = () => {
  return (
    <div className="flex flex-col items-center w-full min-h-screen p-4 bg-gray-100">
      <Helmet>
        <title>The Shoni Gazette</title>
      </Helmet>
      <Header />
      <Main />
    </div>
  );
};

export default App;
