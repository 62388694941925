// src/Main.js
import React, { useState } from 'react';
import NewsCard from './NewsCard';
import Aside from './Aside';
import ChannelSummary from './ChannelSummary';

const Main = () => {
  const [selectedChannel, setSelectedChannel] = useState('data');

  const handleSelectChannel = (channelId) => {
    setSelectedChannel(channelId);
  };

  return (
    <main className="flex flex-col w-full max-w-5xl gap-4 md:flex-row">
      <section className="flex flex-col w-full gap-4 md:w-2/3">
        <NewsCard />
        <ChannelSummary channelId={selectedChannel} />
      </section>
      <Aside onSelectChannel={handleSelectChannel} />
    </main>
  );
};

export default Main;
