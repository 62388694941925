// src/components/ui/Avatar.js
import React from 'react';

export const Avatar = ({ children }) => {
  return (
    <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-200">
      {children}
    </div>
  );
};

export const AvatarImage = ({ src }) => {
  return <img src={src} alt="Avatar" className="w-full h-full object-cover" />;
};

export const AvatarFallback = ({ children }) => {
  return (
    <div className="flex items-center justify-center w-full h-full text-lg text-white bg-gray-400">
      {children}
    </div>
  );
};
