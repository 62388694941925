// src/api.js
import axios from 'axios';

const API_BASE_URL = 'https://us-central1-the-shoni-gazette.cloudfunctions.net/api';

// Fetch daily top 5 summaries
export const fetchDailySummaries = async (date) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/daily/${date}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching daily summaries:', error);
    throw error;
  }
};

// Fetch thread data with custom prompt
export const fetchThreadDataWithPrompt = async (hash, prompt) => {
  try {
    console.log('Sending custom prompt:', prompt); // Debug log
    const response = await axios.post(`${API_BASE_URL}/thread/${hash}/custom-prompt`, {
      replies: 20,
      refresh: false,
      prompt,
      suggestedTokenLimit: 256,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching thread data with custom prompt:', error);
    throw error;
  }
};

// Fetch channel summaries
export const fetchChannelSummaries = async (date) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/all-summaries/${date}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching channel summaries:', error);
    throw error;
  }
};
