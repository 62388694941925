// src/ChannelSummary.js
import React, { useEffect, useState } from 'react';
import { fetchChannelSummaries } from './api';
import ReactMarkdown from 'react-markdown';

const ChannelSummary = ({ channelId }) => {
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    const getSummary = async () => {
      try {
        const date = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
        const data = await fetchChannelSummaries(date);
        setSummary(data[channelId]);
      } catch (error) {
        console.error('Failed to fetch channel summary:', error);
      }
    };
    getSummary();
  }, [channelId]);

  if (!summary) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-8 text-black rounded-md max-w-4xl mx-auto border border-gray-200">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <div className="p-4 border-2 border-black rounded-md mr-4">
            <TypeIcon className="w-12 h-12" />
          </div>
          <div>
            <h1 className="text-3xl font-bold text-blue-800">/{channelId}</h1>
            <ReactMarkdown className="text-blue-800">{summary.aiResult.split('\n')[0]}</ReactMarkdown>
          </div>
        </div>
        <div className="text-right">
          <p>{new Date(summary.startDate + 'T00:00:00Z').toLocaleDateString(undefined, { timeZone: 'UTC' })} -<br />{new Date(summary.endDate + 'T00:00:00Z').toLocaleDateString(undefined, { timeZone: 'UTC' })} utc</p>
        </div>
      </div>
      <div className="space-y-4">
        <ReactMarkdown>{summary.aiResult}</ReactMarkdown>
      </div>
    </div>
  );
};

function TypeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="4 7 4 4 20 4 20 7" />
      <line x1="9" x2="15" y1="20" y2="20" />
      <line x1="12" x2="12" y1="4" y2="20" />
    </svg>
  );
}

export default ChannelSummary;
