import React from 'react';
import { Helmet } from 'react-helmet-async';

const Header = () => {
  return (
    <header className="w-full max-w-5xl p-4 text-center">
      <Helmet>
        <title>The Shoni Gazette - Home</title>
      </Helmet>
      <h1 className="text-4xl font-bold">The Shoni Gazette</h1>
      <p className="text-sm text-gray-600">shit writes itself every wednesday</p>
      <div className="bg-gray-800 bg-opacity-80 p-4 rounded-lg text-white">
        <div className="text-4xl font-bold">
          <div />
        </div>
        <p className="text-sm">Countdown to next publication</p>
        <p className="text-lg text-green-500 mt-2">{'${time.almost}'}</p>
        </div>
    </header>
  );
};

export default Header;
