import React from 'react';

export const Card = ({ className, children }) => {
  return (
    <div className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}>
      {children}
    </div>
  );
};

export const CardContent = ({ children }) => {
  return <div className="p-6">{children}</div>;
};

export const CardHeader = ({ children }) => {
  return <div className="p-6">{children}</div>;
};

export const CardTitle = ({ children }) => {
  return <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">{children}</h3>;
};
